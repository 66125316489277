<template>
  <v-row no-gutters class="tool-bar-style">
    <v-col cols="12" class="d-flex mt-1">
      <v-btn small color="" class="mr-2 mt-2" @click="addText">Add Text</v-btn>
      <v-select
        placeholder="Select font"
        hide-details=""
        aria-orientation="bottom"
        class="mr-3"
        :items="itemsFontsize"
        item-text="name"
        v-model="defaultData.fontSize"
        item-value="id"
      >
      </v-select>
      <!-- <v-text-field
        hide-details=""
        v-model.number="defaultData.maxLength"
        type="number"
        class="mr-1"
        placeholder="Length"
      ></v-text-field> -->
      <v-text-field
        hide-details=""
        v-model="defaultData.text"
        type="text"
        class="mr-1"
        placeholder="Text"
        max="10"
      ></v-text-field>
      <v-combobox
        placeholder="Select Font  family"
        hide-details=""
        aria-orientation="bottom"
        class="mr-3"
        item-text="name"
        item-value="name"
        :items="fonts"
        @change="changeFont"
      >
        <template v-slot:item="{ index, item }">
          <v-chip :color="`#4181cb`" text-color="#eee" label small>
            {{ item.name }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-list-item-action v-if="!item.isPublic" @click.stop="removeFont(item, index)">
            <i class="fas fa-trash-alt" @click.stop="removeFont(item, index)"></i>
          </v-list-item-action>
        </template>
      </v-combobox>
      <label title="Add font" class="myFile2 mb-3 mb-3 d-inline-block pointer">
        <i class="fas fa-plus-circle fz-20 mt-3 pointer"></i>
        <input
          ref="fileUploader"
          style="width: 30px"
          @change="uploadFont"
          type="file"
          class="pointer"
          accept=".ttf,.otf"
        />
      </label>
      <!-- <i class="fas fa-plus-circle fz-20 mt-3 pointer"></i> -->
    </v-col>
    <v-col cols="12" class="d-flex my-1">
      <div v-if="isTextColor" class="position:relative">
        <v-btn outlined :color="defaultData.fontColor" @click="isShowSelectionColor = true">
          <v-icon>mdi mdi-format-color-text</v-icon>
        </v-btn>
        <div class="sketch-color">
          <sketch-picker
            v-click-outside="onClickOutside"
            v-if="isShowSelectionColor"
            @input="updateValue"
            v-model="colors"
          />
        </div>
      </div>
      <v-btn-toggle @change="changeFontStyle" v-model="toggle_multiple" color="primary" dense group multiple>
        <v-btn :value="1" text>
          <v-icon>mdi-format-bold</v-icon>
        </v-btn>

        <v-btn :value="2" text>
          <v-icon>mdi-format-italic</v-icon>
        </v-btn>

        <v-btn :value="3" text>
          <v-icon>mdi-format-underline</v-icon>
        </v-btn>
      </v-btn-toggle>

      <div class="mx-4"></div>
      <v-btn-toggle v-if="isAlign" v-model="defaultData.align" color="primary" dense group mandatory>
        <v-btn :value="'left'" text>
          <v-icon>mdi-format-align-left</v-icon>
        </v-btn>

        <v-btn :value="'center'" text>
          <v-icon>mdi-format-align-center</v-icon>
        </v-btn>

        <v-btn :value="'right'" text>
          <v-icon>mdi-format-align-right</v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-checkbox class="mt-2 mx-2" v-model="defaultData.caps" :label="`All Caps`"></v-checkbox>

      <v-btn class="mt-2" small color="error" outlined @click="$emit('deleteSelection')"> Delete</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import { uploadService } from '@/apis/upload.s';
import fileHelpers from '@/helpers/file';
import { personalizesApi } from '@/apis/personalizes';

import axios from 'axios';
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'productPrice',
    },
    isAlign: {
      type: Boolean,
      default: true,
    },
    fonts: {
      type: Array,
      default: () => [],
    },
    isTextColor: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      colorsText: '',
      isShowSelectionColor: false,
      colors: '#194d33',
      itemsFontsize: [],
      toggle_multiple: [],
      fileHelpers,
      defaultData: {},
      init: true,
    };
  },

  watch: {
    toggle_multiple: {
      handler: function() {
        if (this.toggle_multiple.includes(1)) {
          this.defaultData.bold = true;
        } else this.defaultData.bold = false;
        if (this.toggle_multiple.includes(2)) {
          this.defaultData.italic = true;
        } else this.defaultData.italic = false;
        if (this.toggle_multiple.includes(3)) {
          this.defaultData.underline = true;
        } else this.defaultData.underline = false;
      },
      deep: true,
    },
    data: {
      handler: function() {
        if (!this.$parent.isChangeOption) {
          this.defaultData = Object.assign({}, this.data);
          this.toggle_multiple = [];
          if (this.defaultData.bold) this.toggle_multiple.push(1);
          if (this.defaultData.italic) this.toggle_multiple.push(2);
          if (this.defaultData.underline) this.toggle_multiple.push(3);
        }
      },
      deep: true,
    },
    defaultData: {
      handler: function() {
        if (!this.init && !this.$parent.isChangeOption) this.$emit('updateData', this.defaultData, this.type);
      },
      deep: true,
    },
  },
  mounted() {
    this.init = true;
    for (let i = 8; i <= 500; i++) {
      this.itemsFontsize.push({ name: `${i}px`, id: `${i}px` });
    }
    this.defaultData = Object.assign({}, this.data);
    this.colors = this.defaultData.fontColor;
    this.init = false;
  },
  methods: {
    async removeFont(item) {
      try {
        console.log('item', item);
        await personalizesApi.removeFont(item.id);
        this.$emit('loadFont');
      } catch (error) {}
      console.log(item);
    },
    changeFont(e) {
      if (e && typeof e === 'object') {
        let f = this.fonts.find(item => item.name === e.name);
        this.defaultData.fontFamily = f.name;
        this.$emit('updateData', this.defaultData, this.type);
      }
    },
    clickUploadFile() {
      this.resetImageUploader();
      this.$refs.fileUploader.click();
    },
    resetImageUploader() {
      this.$refs.fileUploader.value = '';
    },
    async uploadFont(e) {
      console.log(e.target.files[0]);
      let inputTypes = ['otf', 'tff'];
      let file = e.target.files[0];
      if (file.name.toLowerCase().includes('.otf') || file.name.toLowerCase().includes('.ttf'))
        try {
          let urlData = await uploadService.get({ filename: `${file.name.replace(/\s/g, '-')}` });
          if (urlData) {
            let res = await axios.put(urlData.data.presignedUrl, file, {
              headers: {
                'Content-Type': `${file.type}`,
              },
            });
            let font = await personalizesApi.uploadFont({
              name: file.name.split('.')[0],
              fontUrl: this.fileHelpers.url(urlData.data.imagePath),
              // isPublic:true
            });
            if (font) {
              this.$emit('loadFont');
            }
          }
        } catch (error) {
          console.log('error', error);
          this.$store.commit('setMessages', {
            messages: 'Error  ',
            type: 'error',
          });
        }
      this.$refs.fileUploader.value = '';
    },
    addText() {
      this.$emit('addText');
    },
    refresh() {
      location.reload();
    },
    changeFontStyle() {
      // console.log('sdsd');
    },
    onClickOutside() {
      this.isShowSelectionColor = false;
    },
    updateValue() {
      //   this.isShowSelectionColor = false;
      this.defaultData.fontColor = this.colors.hex;
    },
  },
};
</script>

<style lang="scss">
.tool-bar-style {
  .v-toolbar__content {
    padding: 0px;
  }
  .sketch-color {
    position: absolute;
    z-index: 1;
    // top: -300px;
  }
  display: flex;
  align-items: center;
  z-index: 1000000000000000000;
  position: relative;
}
</style>
